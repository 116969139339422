import React from 'react';

export const AppStateContext = React.createContext();

const isBrowser = typeof window !== 'undefined';

const localStorageKeys = {
  lang: 'ca.hemlibra.lang',
  highContrast: 'ca.hemlibra.highContrast',
};

const getLocalStorageItem = key => {
  return isBrowser ? window.localStorage.getItem(key) : null;
};

const setLocalStorageItem = (key, value) => {
  isBrowser && window.localStorage.setItem(key, value);
};

const getDefaultLanguage = () => {
  if (isBrowser) {
    const browserLanguage = navigator.language || navigator.userLanguage;
    if (browserLanguage.startsWith('fr-')) {
      return 'french';
    }
  }

  return 'english';
};

export class AppStateProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      highContrast: true,
      language: null,
      page: null,
      section: null,
    };
  }

  componentDidMount() {
    let language = getLocalStorageItem(localStorageKeys.lang);
    if (!language || !['english', 'french'].includes(language)) {
      language = getDefaultLanguage();
    }
    this.setState({ language: language });
    this.loadLanguage(language);
  }

  setLanguage = language => {
    if (!language || !['english', 'french'].includes(language)) {
      language = 'english';
    }

    this.setState({
      language: language,
    });

    setLocalStorageItem(localStorageKeys.lang, language);
  };

  setPage = page => {
    this.setState({
      page: page,
    });
  };

  setSection = section => {
    this.setState({
      section: section,
    });
  };

  toggleContrast = () => {
    const highContrast = !this.state.highContrast;
    this.setState({
      highContrast: highContrast,
    });
    setLocalStorageItem(localStorageKeys.highContrast, highContrast);
  };

  toggleLanguage = () => {
    const language = this.state.language === 'french' ? 'english' : 'french';
    this.setState({
      language: language,
    });
    setLocalStorageItem(localStorageKeys.lang, language);
    this.loadLanguage(language);
  };

  loadLanguage = (language, retryCount = 0) => {
    if (isBrowser && window.OneTrust) {
      const langCode = language === 'french' ? 'fr-ca' : 'en';
      window.OneTrust.changeLanguage(langCode);
    } else if (retryCount < 10) {
      const loadLanguage = this.loadLanguage;
      setTimeout(function () {
        loadLanguage(language, retryCount + 1);
      }, (2 + retryCount) * 50);
    }
  };

  getValue = () => {
    return {
      ...this.state,
      setLanguage: this.setLanguage,
      setPage: this.setPage,
      setSection: this.setSection,
      toggleContrast: this.toggleContrast,
      toggleLanguage: this.toggleLanguage,
    };
  };

  render() {
    return <AppStateContext.Provider value={this.getValue()}>{this.props.children}</AppStateContext.Provider>;
  }
}

export function wrapRootElement({ element }) {
  return <AppStateProvider>{element}</AppStateProvider>;
}
