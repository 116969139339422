// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-fr-js": () => import("./../../../src/pages/fr.js" /* webpackChunkName: "component---src-pages-fr-js" */),
  "component---src-pages-hcp-js": () => import("./../../../src/pages/hcp.js" /* webpackChunkName: "component---src-pages-hcp-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patient-index-js": () => import("./../../../src/pages/patient/index.js" /* webpackChunkName: "component---src-pages-patient-index-js" */),
  "component---src-pages-patient-materials-js": () => import("./../../../src/pages/patient/materials.js" /* webpackChunkName: "component---src-pages-patient-materials-js" */),
  "component---src-pages-patient-risk-min-tools-js": () => import("./../../../src/pages/patient/risk-min-tools.js" /* webpackChunkName: "component---src-pages-patient-risk-min-tools-js" */),
  "component---src-pages-patient-video-js": () => import("./../../../src/pages/patient/video.js" /* webpackChunkName: "component---src-pages-patient-video-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

